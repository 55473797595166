import React from "react";
import {Button, Footer, Result} from "antd-mobile";
import {useNavigate} from "react-router";
import './SuccessValuationApply.less';

/**
 * 报名成功
 *
 * @constructor
 */
const SuccessValuationApply: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={"SuccessValuationApply"}>
      <div className={'success'}>
        <div className={'result'}>
          <Result
            status='success'
            title='报名成功'
            description='请扫码在线支付报名费'
          />
        </div>
        <div className={'pay-code'}>
          <img src={require('../../images/pay.jpg')} alt="在线支付" width={280} height={280}/>
          <span style={{marginTop: 20}}>报名费：¥2680</span>
        </div>
        <div className={'btn'}>
          <Button style={{width: '100%'}} block color='primary' size='large'
                  onClick={() => navigate('/myApply', {state: {tab: 'valuation'}})}>查看报名记录</Button>
        </div>
      </div>
      <Footer content='@2023 ccfa.art All rights reserved'></Footer>
    </div>
  )
}

export default SuccessValuationApply;