import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import global from '../../global';
import {Avatar, Button, List} from "antd-mobile";
import "./InfoValuationApply.less";
import {ListItem} from "antd-mobile/es/components/list/list-item";

/**
 * 查看申请
 * @constructor
 */
export const InfoValuationApply: React.FC = () => {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const [info, setInfo] = useState<any>({}); // TODO

  useEffect(() => {
    if (global.getSessionKey() == null) return;
    if (id === undefined) return;

    //读取报名信息
    dispatch.valuationApply.getInfo({id}).then((res: any) => {
      if (res.code === 0) {
        setInfo(res.data);
      }
    });
  }, [session.isLogin, dispatch.valuationApply, id]);

  return (
    <div className='InfoValuationApply'>
      <div className='content'>
        {
          info && (
            <>
              <List header={'评审信息'}>
                <List.Item extra={info.auditCompanyName}>
                  评审单位
                </List.Item>
                <List.Item extra={info.auditAddress}>
                  评审地址
                </List.Item>
                <List.Item extra={info.auditDate}>
                  评审日期
                </List.Item>
                <List.Item extra={info.specialityName}>
                  专业
                </List.Item>
                <List.Item extra={info.level}>
                  等级
                </List.Item>
              </List>
              <List header={'报名信息'}>
                <List.Item prefix={<Avatar style={{ '--size': '64px' }} src={global.getFileOpenUrl(info.photoFile)} />} description={info.idCardType + ' - ' + info.idCardNumber}>
                  {info.idCardName} - {info.namePinyin}
                </List.Item>
                <List.Item extra={info.birthday}>
                  出生日期
                </List.Item>
                <List.Item extra={info.sex}>
                  性别
                </List.Item>
              </List>
              <List header={'联系信息'}>
                <List.Item extra={info.orgName}>
                  所在机构
                </List.Item>
                <List.Item extra={info.mobile}>
                  联系电话
                </List.Item>
              </List>
              <List header={'报名状态'}>
                <List.Item extra={info.applyStatusName}>
                  报名状态
                </List.Item>
                <ListItem extra={info.created}>
                  报名时间
                </ListItem>
              </List>
            </>
          )
        }
      </div>
      <div style={{marginTop: 10}}>
        <Button style={{width: '100%'}} color={"primary"} onClick={() => navigate("/myApply", {state: {tab: 'valuation'}})}>查看报名历史</Button>
      </div>
    </div>
  )
}