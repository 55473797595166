import React, {useEffect, useState} from 'react';
import './MyValuationApply.less';
import {Avatar, Card, ErrorBlock} from "antd-mobile";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";
import {RightOutline} from 'antd-mobile-icons'

const MyValuationApply: React.FC = () => {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [infos, setInfos] = useState<any>([]);

  useEffect(() => {
    if (global.getSessionKey() == null) return;
    dispatch.valuationApply.getMy().then((res: any) => {
      if (res.code === 0) {
        setInfos(res.data);
      }
    });
  }, [session.isLogin, dispatch.valuationApply]);

  return (
    <div className='MyValuationApply'>
      <div className='content'>
        {
          infos.length > 0 ? infos.map((info: any, index: number) => (
            <Card key={index} className={'list'} extra={<RightOutline/>}
                  title={
                    info.specialityName + ' - ' + global.getTeacherLevelName(info.itemType, info.level)
                  }
                  onHeaderClick={() => navigate('/infoValuationApply/' + info.id, {})}>
              <div className={'body'}>
                <Avatar className={'avatar'}
                        src={global.getFileOpenUrl(info.photoFile)}/>
                <div className={'item'}>
                  <div>姓名：{info.idCardName} - {info.namePinyin}</div>
                  <div>专业：{info.specialityName}</div>
                  <div>评审地址：{info.address}</div>
                  <div>评审日期：{info.auditDate}</div>
                  {/*<div>状态：{info.applyStatusName}</div>*/}
                  {/*<div>时间：{info.created}</div>*/}
                </div>
              </div>
            </Card>
          )) : <ErrorBlock status='empty'/>
        }
      </div>
    </div>
  )
}

export default MyValuationApply;