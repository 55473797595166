import {useSelector} from "react-redux";

/**
 * 主持教师评审班简章
 *
 * @param props
 * @constructor
 */
function HostingDesc(props: { plan: any }) {
  return (
    <div className={"desc"}>
      <div className={"title"}>
        <div>中国文促会</div>
        <div>主持教师评审班简章</div>
      </div>
      <div className={"body"}>
        <div className={"head"}>
          <p>中国文化艺术发展促进会(简称“中国文促会”)是1993
            年经中华人民共和国文化部批准、中华人民共和国民政部注册成立、中央和国家机关工作委员会党建领导的国家级全国性社会组织。</p>
          <p>中国文化艺术发展促进会时尚艺术工委会，是中国文化艺术发展促进会设立的分支机构，具有主导组织主持专业跨省(自治区、直辖市)测评资质的社会艺术水平测评机构。</p>
        </div>
        <div>
          <div className={"level"}>教师等级划分</div>
          <div className={"content"}>
            <p>中国文化艺术发展促进会时尚艺术工委会主持测评辅导教师共4个级别，由低至高分别为初级教师（1-5级），中级教师（6-8级），高级教师（9-10级），特级教师（11-12级）。</p>
            <p>从未考过辅导教师证书的教师应从最低级考起，不可跨级评审；</p>
            <p>晋级更高级别的辅导教师需具有前一等级的资格证书；</p>
            <p>每场评审可连考两级；每年最多可参与两场评审。</p>
          </div>

          <div className={"level"}>考官等级划分</div>
          <div className={"content"}>
            <p>初级评委：熟悉教材6-8级 基础语音发声、专业能力、教学能力、测评准则；</p>
            <p>中级评委：熟悉教材9-10级 基础语音发声、专业能力、教学能力、测评准则；</p>
            <p>高级评委：熟悉教材11-12级 基础语音发声、专业能力、教学能力、测评准则；</p>
          </div>

          <div className={"level"}>考核方式</div>
          <div className={"content"}>
            <p>考核内容为理论知识、示范能力及评估能力；</p>
            <p>理论知识测评以线上为主，主要考核考官从事本职业应掌握的基本要求和相关知识要求；示范能力及评估能力考评主要采用现场表演及答辩等方式进行，主要考核考官从事本职业应具备的技能水平；</p>
            <p>理论知识和示范能力及测评能力实行百分制，成绩达 70 分及以上者为合格；</p>
            <p>考核指标包括：理论知识，基础语音发声，舞台综合能力，示范能力，执评准则；</p>
          </div>

          <div className={"content"}>
            <div>【评审日期】 {props.plan.info.auditDate}</div>
            <div>【报名截止】 {props.plan.info.expireDate}</div>
            <div>【评审地点】 {props.plan.info.address}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * 广场舞教师评审班简章
 *
 * @param props
 * @constructor
 */
function SquareDanceDesc(props: { plan: any }) {
  return (
    <div className={"desc"}>
      <div className={"title"}>
        <div>中国文促会</div>
        <div>广场舞教师评审班简章</div>
      </div>
      <div className={"body"}>
        <div className={"head"}>
          <p>中国文化艺术发展促进会(简称“中国文促会”)是1993
            年经中华人民共和国文化部批准、中华人民共和国民政部注册成立、中央和国家机关工作委员会党建领导的国家级全国性社会组织。</p>
          <p>中国文化艺术发展促进会时尚艺术工委会，是中国文化艺术发展促进会设立的分支机构，具有主导组织主持专业跨省(自治区、直辖市)测评资质的社会艺术水平测评机构。</p>
        </div>
        <div>
          <div className={"level"}>教师等级划分</div>
          <div className={"content"}>
            <p>中国文化艺术发展促进会时尚艺术工委会主持测评辅导教师共4个级别，由低至高分别为初级教师（1-4级），中级教师（5-8级），高级教师（9-10级），特级教师（11-12级）。</p>
            <p>从未考过辅导教师证书的教师应从最低级考起，不可跨级评审；</p>
            <p>晋级更高级别的辅导教师需具有前一等级的资格证书；</p>
            <p>每场评审可连考两级；每年最多可参与两场评审。</p>
          </div>

          <div className={"level"}>考官等级划分</div>
          <div className={"content"}>
            <p>初级评委：熟悉教材5-8级 专业知识、职业素养、教学能力、测评准则；</p>
            <p>中级评委：熟悉教材9-10级 专业知识、职业素养、教学能力、测评准则；</p>
            <p>高级评委：熟悉教材11-12级 专业知识、职业素养、教学能力、测评准则；</p>
          </div>

          <div className={"level"}>考核方式</div>
          <div className={"content"}>
            <p>考核内容为理论知识、示范能力及评估能力；</p>
            <p>理论知识测评以线上为主，主要考核考官从事本职业应掌握的基本要求和相关知识要求；示范能力及评估能力考评主要采用现场表演及答辩等方式进行，主要考核考官从事本职业应具备的技能水平；</p>
            <p>理论知识评审和示范能力及测评能力实行百分制，成绩达 70 分及以上者为合格；</p>
            <p>考核指标包括：专业知识、职业素养，舞台综合能力，示范能力，执评准则；</p>
          </div>

          <div className={"content"}>
            <div>【评审日期】 {props.plan.info.auditDate}</div>
            <div>【报名截止】 {props.plan.info.expireDate}</div>
            <div>【评审地点】 {props.plan.info.address}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * 第一步：显示评审简章
 */
const ApplyDescription = () => {
  const plan = useSelector((state: any) => state.plan);
  //这里要想办法显示富文本信息
  return (
    <div className='Description'>
      {/*<div className={"body"} dangerouslySetInnerHTML={{__html:plan.info.description}}></div>*/}
      {
        plan.info.itemType === 'HOSTING' && (
          <HostingDesc plan={plan}/>
        )
      }
      {
        plan.info.itemType === 'SQUARE_DANCE' && (
          <SquareDanceDesc plan={plan}/>
        )
      }
    </div>
  )
}

export default ApplyDescription