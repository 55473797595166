import React, {useEffect, useState} from 'react';
import './NewValuationApply.less';
import {useDispatch, useSelector} from "react-redux";
import {Button, Footer, Input, List, Steps, Toast} from 'antd-mobile';

import poster from '../../images/apply_poster.jpg';
import {ListItem} from "antd-mobile/es/components/list/list-item";
import {useNavigate} from "react-router";
import global from "../../global";
import ValuationApplyDescription from "./ValuationApplyDescription";
import {PhotoUploader, SelectLevel,} from "./apply-util";

const {Step} = Steps

/**
 * 海报
 * @constructor
 */
function Poster() {
  const plan = useSelector((state: any) => state.plan);
  const dispatch = useDispatch();
  return (
    <div className='Poster' style={{background: `url(${poster}) no-repeat center fixed`, backgroundSize: 'cover'}}>
      <div className='btn-box'>
        <div className='title'>{plan.info.specialityName}评审班</div>
        <Button shape={"rounded"} className="reg-btn" size='large'
                onClick={() => dispatch.valuationApply.setData({steps: 0})}>立即报名</Button>
      </div>
    </div>
  )
}

/**
 * 第二步：报名信息
 */
function ApplyInfo() {
  const plan = useSelector((state: any) => state.plan);
  const valuationApply = useSelector((state: any) => state.valuationApply);
  const [visibleLevel, setVisibleLevel] = useState(false);
  const dispatch = useDispatch();

  /**
   * 微信授权
   */
  useEffect(() => {
    dispatch.weixin.getJsConfig({url: window.location.href}).then((res: any) => {
      wx.config({
        debug: false,
        appId: global.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData', 'downloadImage', 'getLocalImgData'],
        openTagList: ['wx-open-launch-weapp'],
      })
    });
  }, [dispatch.weixin]);

  /**
   * 生成图片key
   */
  useEffect(() => {
    if (valuationApply.photo) return;
    dispatch.file.genKey({folder: 'photo'}).then((res: any) => {
      dispatch.valuationApply.setData({photo: res.data});
    });
  }, [valuationApply.photo, dispatch.valuationApply, dispatch.file]);

  // useEffect(() => {
  //   //默认5级
  //   dispatch.valuationApply.setData({level: 5});
  // }, [dispatch.valuationApply]);

  return (
    <div className="ExamInfo">
      <List header={""}>
        <ListItem extra={plan.info.address}>
          评审地址
        </ListItem>
        <ListItem extra={plan.info.auditDate}>
          评审日期
        </ListItem>
        <ListItem extra={plan.info.specialityName}>
          专业
        </ListItem>
        <ListItem extra={valuationApply.levelName} onClick={() => setVisibleLevel(true)}>
          级别
        </ListItem>
      </List>

      <List header={"个人信息"}>
        <ListItem
          extra={<Input className={"input"} defaultValue={valuationApply.idCardName} clearable placeholder='请输入姓名'
                        onChange={(v) => dispatch.valuationApply.setData({idCardName: v})}/>}>
          姓名
        </ListItem>
        <ListItem
          extra={<Input className={"input"} defaultValue={valuationApply.idCardNumber} placeholder='请输入证件号码'
                        onChange={(v) => dispatch.valuationApply.setData({idCardNumber: v})}/>}>
          身份证号码
        </ListItem>
        <ListItem extra={<Input className={"input"} type={"number"} defaultValue={valuationApply.mobile}
                                placeholder='请输入联系电话' clearable
                                onChange={(v) => dispatch.valuationApply.setData({mobile: v})}/>}>
          联系电话
        </ListItem>
        {
          //主持教师需要填写
          plan.info.itemType === 'HOSTING' && (
            <ListItem extra={<Input className={"input"} defaultValue={valuationApply.orgName} placeholder='请输入所在机构' clearable
                                    onChange={(v) => dispatch.valuationApply.setData({orgName: v})}/>}>
              所在机构
            </ListItem>
          )
        }

      </List>

      <List header={"证件照片"} className={"list"}>
        <ListItem extra={<wx-open-launch-weapp
          id="launch-btn"
          username="gh_d69aab8d620c"
          path="pages/index/index"
        >
          <script type="text/wxtag-template">
            打开制作小程序
          </script>
        </wx-open-launch-weapp>}>
          制作证件照
        </ListItem>
        <ListItem extra={<PhotoUploader fileList={valuationApply.photoFileList}/>}>
          上传照片（二寸）
        </ListItem>
      </List>

      <SelectLevel visible={visibleLevel} setVisible={setVisibleLevel}/>

      <Footer content='@ 2023 Taoyun.art All rights reserved'></Footer>
    </div>
  )
}

/**
 * 大于1步才显示头部步骤条
 *
 * @constructor
 */
function StepsHeader() {
  const valuationApply = useSelector((state: any) => state.valuationApply);

  return valuationApply.steps > 0 ? (
    <div className='StepsHeader'>
      <Steps current={valuationApply.steps}>
        <Step title='报名须知'/>
        <Step title='报名信息'/>
      </Steps>
    </div>
  ) : null;
}

/**
 * 大于1步才显示底部按钮
 *
 * @constructor
 */
const StepsFooter = () => {
  const valuationApply = useSelector((state: any) => state.valuationApply);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkApply = [
    {name: 'level', value: '请选择级别'},
    {name: 'idCardName', value: '请输入姓名'},
    {name: 'idCardNumber', value: '请输入证件号码'},
    // {name: 'orgName', value: '请输入机构名称'},
    {name: 'mobile', value: '请输入联系电话'},
    // {name: 'address', value: '请输入联系地址'},
    // {name: 'photo', value: '请上传照片'},
  ];

  /**
   * 下一步
   *
   * @param step
   */
  const onStepBtn = (step: number) => {
    let steps = valuationApply.steps + step;
    dispatch.valuationApply.setData({steps});
  }

  /**
   * 完成
   */
  const onFinish = () => {
    //检查普通字段
    for (let i = 0; i < checkApply.length; i++) {
      if (valuationApply[checkApply[i].name] === '') {
        Toast.show({
          icon: 'fail',
          content: checkApply[i].value,
        });
        return;
      }
    }
    //检查身份证号码
    if (valuationApply.idCardNumber.length !== 18) {
      Toast.show({
        icon: 'fail',
        content: '请输入18位身份证号码',
      });
      return;
    }
    //检查上传照片
    if (valuationApply.photoFileList.length === 0) {
      Toast.show({
        icon: 'fail',
        content: '请上传照片',
      });
      return;
    }
    //提交
    dispatch.valuationApply.apply().then((res: any) => {
      if (res.code === 0) {
        Toast.show({
          icon: 'success',
          content: '报名成功',
        })
        navigate("/successValuationApply", {});//跳转到报名成功页面
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg,
        })
      }
    });
  }

  return valuationApply.steps >= 0 ? (
    <div className='StepsFooter'>
      {valuationApply.steps === 0 ? (
        <div className='BtnDiv'>
          <Button block shape='default' color='primary' size='large'
                  onClick={() => onStepBtn(1)}>我已阅读，继续报名</Button>
        </div>
      ) : (
        <>
          <div className='BtnDiv'>
            <Button block shape='default' size='large' onClick={() => onStepBtn(-1)}>上一步</Button>
          </div>
          <div className='BtnDiv'>
            {valuationApply.steps < 1 ? (
              <Button block shape='default' size='large' color='primary'
                      onClick={() => onStepBtn(1)}>下一步</Button>) : (
              <Button block shape='default' size='large' color='primary' onClick={onFinish}>提交报名</Button>)}
          </div>
        </>)}
    </div>
  ) : null;
}

/**
 * 评审申请
 *
 * @constructor
 */
const NewValuationApply: React.FC = () => {
  const session = useSelector((state: any) => state.session);
  const valuationApply = useSelector((state: any) => state.valuationApply);
  const dispatch = useDispatch();

  useEffect(() => {
    if (global.getSessionKey() == null) return;

    const planId = new URLSearchParams(window.location.search).get('planId');
    if (planId != null && planId !== '') {
      dispatch.valuationApply.setData({planId});
      dispatch.plan.info({planId});
    } else {
      console.error("没有传入planId")
    }
  }, [session.isLogin, dispatch.valuationApply, dispatch.plan]);

  return (
    <div className='NewValuationApply'>
      <div style={{height: '100%'}}>
        <StepsHeader/>
        {valuationApply.steps === -1 && <Poster/>}
        {valuationApply.steps === 0 && <ValuationApplyDescription/>}
        {valuationApply.steps === 1 && <ApplyInfo/>}
      </div>
      <StepsFooter/>
    </div>
  )
}

export default NewValuationApply;