export interface UserInfo {
  nickName: string
  mobile: string
  name: string
  avatar: string
}

/**
 * 全局配置
 */
const global = {
  name: "焘韵文化",
  company: "上海焘韵文化传播有限公司",
  appId: "wx64da7439b29bc360",

  //版本
  version: "0.1",

  //环境，通过请求地址自动判断是否开发环境
  env: window.location.href.startsWith("http://localhost") ? "dev" : "pro",

  //接口服务器
  apiServers: [
    {
      env: "dev",
      url: "/dev-api",  //开发环境下使用代理
    },
    {
      env: "pro",
      url: "https://wemp.taoyun.art/api/",
    },
  ],

  //获取接口地址
  getApiServer(): string {
    let url: string = ""
    this.apiServers.forEach((item: any): void => {
      if (item.env === this.env) url = item.url
    })
    return url
  },

  //获取令牌
  getSessionKey(): string | null {
    //dev环境下不需要登录
    if (this.env === "dev") {
      return "b476bd9bb892dbaf5f1f47cf22b1e220f6d0edacee8d5c755c3d523ceab2d01e"
    }

    let sessionKey: string | null = localStorage.getItem("sessionKey");
    if (sessionKey === 'undefined' || sessionKey == null) {
      return null
    }

    let expireTime: string | null = localStorage.getItem("expireTime")
    if (expireTime != null && new Date().getTime() < Date.parse(expireTime.replace(/-/g, '/'))) {
      return sessionKey
    }

    return null
  },

  //保存令牌
  saveSessionKey(sessionKey: string, expireTime: string): void {
    localStorage.setItem("sessionKey", sessionKey)
    localStorage.setItem("expireTime", expireTime)
  },

  //清空令牌
  clearSessionKey(): void {
    localStorage.removeItem("expireTime")
    localStorage.removeItem("sessionKey")
  },

  //获取当前用户信息
  getUserInfo(): UserInfo | {} {
    let userInfo = localStorage.getItem("userInfo")
    if (userInfo != null && userInfo !== "") {
      return JSON.parse(userInfo)
    }
    return {}
  },

  //保存用户信息
  saveUserInfo(userInfo: {}): void {
    localStorage.setItem("userInfo", JSON.stringify(userInfo))
  },

  //获取文件地址
  getFileOpenUrl(data: any) {
    if (data !== undefined)
      return this.getApiServer() + '/pub/v1/file/open?fileKey=' + encodeURI(data.fileKey) + '&accessKey=' + data.accessKey
    return '';
  },

  /**
   * 老师报名等级
   *
   * @param itemType
   * @returns
   */
  getTeacherLevels(itemType: string) {
    if (itemType === 'HOSTING')
      return [
        {value: 5, label: "初级(1-5)"},
        {value: 8, label: "中级(6-8)"},
        {value: 10, label: "高级(9-10)"},
        {value: 12, label: "特级(11-12)"},
      ]
    if (itemType === 'SQUARE_DANCE')
      return [
        {value: 4, label: "初级(1-4)"},
        {value: 8, label: "中级(5-8)"},
        {value: 10, label: "高级(9-10)"},
        {value: 12, label: "特级(11-12)"},
      ]
    return [];
  },

  /**
   * 根据级别获取名称
   *
   * @param itemType
   * @param level
   */
  getTeacherLevelName(itemType: string, level: number) {
    let levels = this.getTeacherLevels(itemType)
    for (let i = 0; i < levels.length; i++) {
      if (levels[i].value === level) {
        return levels[i].label
      }
    }
    return ""
  }
}

export default global
