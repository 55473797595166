import React, {useEffect, useState} from 'react';
import './MyExamApply.less';
import {Avatar, Card, ErrorBlock} from "antd-mobile";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";
import {RightOutline} from 'antd-mobile-icons'

const MyExamApply: React.FC = () => {
  const session = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [infos, setInfos] = useState<any>([]);

  useEffect(() => {
    if (global.getSessionKey() == null) return;
    dispatch.examApply.getMy().then((res: any) => {
      if (res.code === 0) {
        setInfos(res.data);
      }
    });
  }, [session.isLogin, dispatch.examApply]);

  return (
    <div className='MyExamApply'>
      <div className='content'>
        {
          infos.length > 0 ? infos.map((info: any, index: number) => (
            <Card key={index} className={'list'} extra={<RightOutline/>}
                  title={info.specialityName + ' - ' + info.level + '级别'}
                  onHeaderClick={() => navigate('/infoExamApply/' + info.id, {})}>
              <div className={'body'}>
                <Avatar className={'avatar'}
                        src={global.getFileOpenUrl(info.photoFile)}/>
                <div className={'item'}>
                  <div>考生：{info.idCardName} - {info.namePinyin}</div>
                  <div>考点：{info.auditCompanyName}</div>
                  <div>日期：{info.auditDate}</div>
                  <div>老师：{info.teacher} - {info.teacherMobile}</div>
                  <div>状态：{info.applyStatusName}</div>
                  <div>时间：{info.created}</div>
                </div>
              </div>
            </Card>
          )) : <ErrorBlock status='empty'/>
        }
      </div>
    </div>
  )
}

export default MyExamApply;