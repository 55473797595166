import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";
import global from "../global";

export const examApply = createModel<RootModel>()({
  state: {
    planId: 0,//计划id

    steps: -1,//当前步骤
    inputPinyin: false,//是否输入拼音

    level: '',//级别

    idCardName: '',
    namePinyin: '',//拼音
    idCardType: '身份证',//证件类型
    idCardNumber: '',

    birthday: '',//出生日期
    sex: '',//性别

    country: '中国',//国家
    nation: '汉族',//民族
    area: '',//地区
    address: '',//地址

    photo: '',//照片

    orgName: '',//单位名称
    teacher: '',//指导老师
    teacherMobile: '',//老师电话

    parents: '',//家长姓名
    mobile: '',//家长电话

    auditDate: '',//审核时间
    expireDate: '',//截止日期
    companyName: '',//公司名称

    photoFileList: [],//文件列表
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 读取用户信息
     * @param payload
     * @param rootState
     */
    async getInfo(payload, rootState) {
      let res = await request.get("/wemp/v1/exam/apply/info/" + payload.id)
      if (res > 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 我的历史申请
     * @param payload
     * @param rootState
     */
    async getMy(payload, rootState) {
      let res = await request.get("/wemp/v1/exam/apply/my/WECHAT")
      if (res > 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 上传照片
     *
     * @param payload
     * @param rootState
     */
    async upload(payload, rootState) {
      let fileKey: string = rootState.examApply.photo;
      let res = await request.upload("/pub/v1/file/upload/cover", payload, {fileType: 'PRIVATE', fileKey});
      if (res.code > 0) {
        console.log(res.msg)
      }
      let url = global.getFileOpenUrl(res.data);
      return {url, key: res.data.fileKey, thumbnailUrl: url, extra: res.data.contentType};
    },

    /**
     * 申请测评
     * @param payload
     * @param rootState
     */
    async apply(payload, rootState) {
      let data: any = {
        planId: rootState.examApply.planId,
        level: rootState.examApply.level,

        idCardName: rootState.examApply.idCardName,
        namePinyin: rootState.examApply.namePinyin,

        idCardType: rootState.examApply.idCardType,
        idCardNumber: rootState.examApply.idCardNumber,
        birthday: rootState.examApply.birthday,
        sex: rootState.examApply.sex,

        country: rootState.examApply.country,
        nation: rootState.examApply.nation,
        area: rootState.examApply.area,
        address: rootState.examApply.address,

        photo: rootState.examApply.photo,

        orgName: rootState.examApply.orgName,
        teacher: rootState.examApply.teacher,
        teacherMobile: rootState.examApply.teacherMobile,
        parents: rootState.examApply.parents,
        mobile: rootState.examApply.mobile,
      }
      let res = await request.post("/wemp/v1/exam/apply", data)
      if (res.code > 0) {
        console.log(res.msg);
      }
      return res;
    },

  }),
})