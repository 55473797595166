import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Cascader, DatePicker, ImageUploader, ImageUploadItem, Picker, Toast} from 'antd-mobile';

/**
 * 选择级别
 * @param props
 * @constructor
 */
export const SelectLevel = (props: { visible: boolean, setVisible: any }) => {
  const plan = useSelector((state: any) => state.plan);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    const basicColumns: any[] = [];
    basicColumns.push(plan.info.grades.map((item: any) => {
      return {label: item.name, value: item.level}
    }));
    // console.log(basicColumns);
    setColumns(basicColumns);
  }, [plan.info.grades]);

  return (
    <Picker
      title='选择级别'
      columns={columns}
      visible={props.visible}
      // value={examApply.level}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => dispatch.examApply.setData({level: v[0]})}
    />
  )
}

/**
 * 选择国家
 * @param props
 * @constructor
 */
export const SelectCountry = (props: { visible: boolean, setVisible: any }) => {
  const country = useSelector((state: any) => state.country);
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    const basicColumns: any[] = [];
    basicColumns.push(country.list.map((item: any) => {
      return {label: item.name, value: item.name}
    }));
    setColumns(basicColumns);
  }, [country.list]);

  return (
    <Picker
      title='选择国家'
      columns={columns}
      visible={props.visible}
      value={examApply.country}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => dispatch.examApply.setData({country: v[0]})}
    />
  )
}

/**
 * 选择民族
 * @param props
 * @constructor
 */
export const SelectNation = (props: { visible: boolean, setVisible: any }) => {
  const nation = useSelector((state: any) => state.nation);
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    const basicColumns: any[] = [];
    basicColumns.push(nation.list.map((item: any) => {
      return {label: item.name, value: item.name}
    }));
    setColumns(basicColumns);
  }, [nation.list]);

  return (
    <Picker
      title='选择民族'
      columns={columns}
      visible={props.visible}
      value={examApply.nation}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => dispatch.examApply.setData({nation: v[0]})}
    />
  )
}

/**
 * 选择出生日期
 * @param props
 * @constructor
 */
export const SelectBirthday = (props: { visible: boolean, setVisible: any }) => {
  const dispatch = useDispatch();

  const min = new Date();
  min.setFullYear(min.getFullYear() - 20);

  return (
    <DatePicker
      visible={props.visible}
      onClose={() => {
        props.setVisible(false)
      }}
      min={min}
      //defaultValue={new Date(examApply.birthday)}
      onConfirm={val => {
        const birthday = val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate();
        dispatch.examApply.setData({birthday: birthday})
      }}
    />
  )
}

/**
 * 选择性别
 * @param props
 * @constructor
 */
export const SelectSex = (props: { visible: boolean, setVisible: any }) => {
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const columns = [
    [
      {label: '男', value: '男'},
      {label: '女', value: '女'},
    ]
  ]

  return (
    <Picker
      title='选择性别'
      columns={columns}
      visible={props.visible}
      value={examApply.nation}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => dispatch.examApply.setData({sex: v[0]})}
    />
  )
}

/**
 * 选择证件类型
 * @param props
 * @constructor
 */
export const SelectIdCardType = (props: { visible: boolean, setVisible: any }) => {
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const columns = [
    [
      {label: '身份证', value: '身份证'},
      {label: '护照', value: '护照'},
      {label: '港澳通行证', value: '港澳通行证'}
    ]
  ]

  return (
    <Picker
      title='选择证件类型'
      columns={columns}
      visible={props.visible}
      value={examApply.nation}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => dispatch.examApply.setData({idCardType: v[0]})}
    />
  )
}

/**
 * 选择拼音
 * @param props
 * @constructor
 */
export const SelectPinyin = (props: { visible: boolean, setVisible: any }) => {
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    dispatch.pinyin.list(examApply.idCardName).then((res: any) => {

      const list = res.data.map((item: string) => {
        return {label: item, value: item}
      })
      list.push({label: '手动输入', value: '手动输入'});

      const basicColumns: any[] = [];
      basicColumns.push(list);

      setColumns(basicColumns);
    })
  }, [examApply.idCardName, dispatch.pinyin]);

  const onConfirm = (v: any) => {
    if (v['0'] === '手动输入') {
      dispatch.examApply.setData({inputPinyin: true});
      return;
    }
    dispatch.examApply.setData({namePinyin: v[0]});
  }

  return (
    <Picker
      title='选择拼音'
      columns={columns}
      visible={props.visible}
      // value={examApply.namePinyin}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={onConfirm}
    />
  )
}

/**
 * 选择地区
 * @param props
 * @constructor
 */
export const SelectArea = (props: { visible: boolean, setVisible: any }) => {
  const city = useSelector((state: any) => state.city);
  const dispatch = useDispatch();

  return (
    <Cascader
      options={city.list}
      visible={props.visible}
      onClose={() => {
        props.setVisible(false)
      }}
      // value={examApply.area}
      onConfirm={(v) => dispatch.examApply.setData({area: v.join('/')})}
      // onSelect={(val, extend) => {
      //   console.log('onSelect', val, extend.items)
      // }}
    />
  )
}

/**
 * 上传照片
 * @constructor
 */
export const PhotoUploader = (props: { fileList: ImageUploadItem[] }) => {
  const dispatch = useDispatch();

  function beforeUpload(file: File) {
    if (file.size > 1024 * 2048) {
      // Toast.show('请选择小于 500K 的图片，可以用制作证件照小程序处理保存')
      Toast.show('证件照文件不能大于2兆')
      return null
    }
    return file
  }

  return (
    <ImageUploader style={{margin: 10}}
                   value={props.fileList}
                   onChange={(v) => dispatch.examApply.setData({photoFileList: v})}
                   upload={(files: File) => dispatch.examApply.upload(files)}
                   showUpload={true}
                   multiple={false}
                   maxCount={1}
                   beforeUpload={beforeUpload}
      // onCountExceed={exceed => {
      //   Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
      // }}
    />
  )
}

