import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const weixin = createModel<RootModel>()({
  state: 0, // initial state
  reducers: {
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 获取配置
     * @param payload
     * @param rootState
     */
    async getJsConfig(payload, rootState) {
      let res = await request.get("/wemp/jsConfig/?url=" + encodeURIComponent(payload.url.split('#')[0]))
      if (res.code > 0) {
        console.log(res.msg);
      }
      return res;
    },
  }),
})