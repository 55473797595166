import {useSelector} from "react-redux";
import content1 from '../../images/content1.jpg';
import price2 from '../../images/price2.jpg';
import price11 from '../../images/price11.jpg';
import React from "react";
import {Helmet} from "react-helmet";

/**
 * 主持专业测评简章
 *
 * @param plan
 * @constructor
 */
function HostingDesc(plan: any) {
  return <div className={'desc'}>
    <div className={'title'}>
      <div>中国文促会</div>
      <div>主持专业测评简章</div>
    </div>
    <div className={'body'}>
      <div className={'head'}>
        <p>中国文化艺术发展促进会(简称“中国文促会”)是1993
          年经中华人民共和国文化部批准、中华人民共和国民政部注册成立、中央和国家机关工作委员会党建领导的国家级全国性社会组织。</p>
        <p>中国文化艺术发展促进会主持测评等级由低到高分为一至十二级，考生可以根据报考的要求，针对自身现有条件，申报相应的水平测试。</p>
      </div>
      <div>
        <div className={'level'}>一、测评内容、方式</div>
        <div className={'content'}>
          <img src={content1} alt={'测评内容、方式'} width={'100%'}/>
        </div>

        <div className={'level'}>二、收费标准</div>
        <div className={'content'}>
          <img src={price11} alt={'价格'} width={'100%'}/>
        </div>

        <div className={'level'}>三、测评形式</div>
        <div className={'content'}>
          <p>中国文促会（主持）测评形式： 现场展示，视频辅助。</p>
        </div>

        <div className={'level'}>四、测试大纲</div>
        <div className={'content'}>
          <p>《测试大纲》即《主持专业综合素质培训与测评规范》，中国文化艺术发展促进会主持测评体系汇聚了众多国内一流的播音主持艺术家、朗诵艺术教育专家，中央和地方媒体、高等专业院校以及艺术院团等骨干高水平师资团队，是语言艺术表演专家们集多年表演和教学经验，在反复讨论、研究推敲、多次实践总结的基础上，共同研发编写而成的。</p>
        </div>

        <div className={'level'}>五、报考须知</div>
        <div className={'content'}>
          <p>
            （一）考生在授课教师的指导下，在全国各地区测评单位报名，考生需依据中国文促会主持测评教材或大纲科学报考，切忌盲目与虚高：<br/>
            幼儿：1～2级，逐级报考；<br/>
            初级：3～5级，逐级报考；<br/>
            中级：6～8级，逐级报考；<br/>
            高级：9～12级，逐级报考。<br/>
            初次报名：<br/>
            1、7岁以下（不含7岁）的考生必须从“一级”起报。<br/>
            2、7岁以上（含7岁）的考生从“三级”起报。<br/>
            同级认证方式：已获证书者提供相应等级证书即可。<br/>
            例：7岁以下（不含7岁）的考生报考“三级”水平，报名时须携带相关协会主持专业测评二级证书的原件、复印件或图片，经工作人员审核后予以报考。<br/>
            每次报考只考本级相应测试内容，无其他加试内容，不加收其他级别费用。
          </p>
          <p>
            （二）6级（含6级）以下，考生每次测试最多可以连报两个级别，需缴纳两级费用。7级（含7级）以上每次只能报一个级别，已经办理完报名手续后，恕不办理改报或退报手续。
          </p>
          <p>
            （三）完整、准确、清晰填写或打印报名表，每人一张报名表。
          </p>
          <p>
            （四）报名时每人上传2寸电子彩色照片。
          </p>
          <p>
            （五）考生报名后，不能按规定时间参加测试，另行安排时间补测。
          </p>
          <p>
            （六）考生须按报名表所示时间、地点参加测试，自觉遵守测评纪律。无报名表或报名表与考生身份不符者，均不准进入考场。
          </p>
        </div>

        <div className={'level'}>六、测评时间安排</div>
        <div className={'content'}>
          <div>【地区范围】 {plan.info.area}</div>
          <div>【测评日期】 {plan.info.auditDate}</div>
          <div>【报名截止】 {plan.info.expireDate}</div>
          <div>【测评地点】 {plan.info.address} {plan.info.auditCompanyName}</div>
        </div>
      </div>
    </div>
  </div>;
}

/**
 * 广场舞专业测评简章
 *
 * @param plan
 * @constructor
 */
function SquareDanceDesc(plan: any) {
  return <div className={'desc'}>
    <div className={'title'}>
      <div>中国文促会</div>
      <div>广场舞专业测评简章</div>
    </div>
    <div className={'body'}>
      <div className={'head'}>
        <p>中国文化艺术发展促进会(简称“中国文促会”)是1993
          年经中华人民共和国文化部批准、中华人民共和国民政部注册成立、中央和国家机关工作委员会党建领导的国家级全国性社会组织。</p>
        <p>中国文化艺术发展促进会，是中国文化艺术发展促进会设立的分支机构，具有主导组织主持专业跨省(自治区、直辖市)测评资质的社会艺术水平测评机构。</p>
        <p>中国文化艺术发展促进会广场舞测评等级由低到高分为一至十二级，考生可以根据报考的要求，针对自身现有条件，申报相应的水平测试。</p>
      </div>
      <div>
        <div className={'level'}>一、测评内容、方式和收费标准</div>
        <div className={'content'}>
          <img src={price2} alt={'价格'} width={'100%'}/>
          <span>备注：另收证书费30元/级别(含证书制作及封套费用)</span>
        </div>

        <div className={'level'}>二、测评形式</div>
        <div className={'content'}>
          <p>中国文促会（广场舞）测评形式： 现场展示，视频辅助。</p>
        </div>

        <div className={'level'}>三、测试大纲</div>
        <div className={'content'}>
          <p>《测试大纲》即《广场舞专业综合素质培训与测评规范》，中国文化艺术发展促进会广场舞测评体系汇聚了众多国内一流的舞蹈艺术家、舞蹈艺术教育专家，中央和地方媒体、高等专业院校以及艺术院团等骨干高水平师资团队，是舞蹈艺术表演专家们集多年表演和教学经验，在反复讨论、研究推敲、多次实践总结的基础上，共同研发编写而成的。</p>
        </div>

        <div className={'level'}>四、报考须知</div>
        <div className={'content'}>
          <p>
            （一）考生在授课教师的指导下，在全国各地区测评单位报名，考生需依据中国文促会广场舞测评教材或大纲科学报考，切忌盲目与虚高：<br/>
            初级：1～4级；<br/>
            中级：5～8级；<br/>
            高级：9～10级；<br/>
            特级：11～12级；<br/>
            初次报名：<br/>
            1、初级报名可以直接线上报名，上传视频进行评审。也可以联系当地考点报考。<br/>
            2、初级以上由当地指导单位安排评审老师，在考点现场评审。<br/>
            同级认证方式：已获证书者提供相应等级证书即可。<br/>
            备注：报名需提供姓名、身份证号码、手机号码、两寸照片。<br/>
            每次报考只考本级相应测试内容，无其他加试内容，不加收其他级别费用。
          </p>
          <p>
            （二）初、中、高、特每次只能报一个级别，具体等级由评审老师打分确定；已经办理完报名手续后，恕不办理改报或退报手续。
          </p>
          <p>
            （三）完整、准确、清晰填写或打印报名表，每人一张报名表。
          </p>
          <p>
            （四）报名时每人上传电子照片并提供近期2寸彩色照片1张，《广场舞专业综合素质培训与测评规范证书》使用。
          </p>
          <p>
            （五）考生报名后，不能按规定时间参加测试，另行安排时间补测。
          </p>
          <p>
            （六）考生须按报名表所示时间、地点参加测试，自觉遵守测评纪律。无报名表或报名表与考生身份不符者，评审结果无效。
          </p>
        </div>

        <div className={'level'}>五、测评时间安排</div>
        <div className={'content'}>
          <div>【地区范围】 {plan.info.area}</div>
          <div>【测评日期】 {plan.info.auditDate}</div>
          <div>【报名截止】 {plan.info.expireDate}</div>
          <div>【测评地点】 {plan.info.address} {plan.info.auditCompanyName}</div>
        </div>
      </div>
    </div>
  </div>;
}

/**
 * 第一步：显示测评简章
 */
const ExamApplyDescription = () => {
  const plan = useSelector((state: any) => state.plan);
  //这里要想办法显示富文本信息
  return (
    <div className='Description'>
      <Helmet>
        <title>CCFA报名-介绍</title>
      </Helmet>
      {/*<div className={"body"} dangerouslySetInnerHTML={{__html:plan.info.description}}></div>*/}
      {
        plan.info.itemType === 'HOSTING' && HostingDesc(plan)
      }
      {
        plan.info.itemType === 'SQUARE_DANCE' && SquareDanceDesc(plan)
      }
    </div>
  )
}

export default ExamApplyDescription