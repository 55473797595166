import React, {useEffect, useState} from 'react';
import './NewExamApply.less';
import {useDispatch, useSelector} from "react-redux";
import {Button, Input, List, Steps, Toast} from 'antd-mobile';
import {Helmet} from "react-helmet";
import poster from '../../images/apply_poster.jpg';
import {ListItem} from "antd-mobile/es/components/list/list-item";
import idCard from "../../util/idCard";
import {useNavigate} from "react-router";
import global from "../../global";
import ExamApplyDescription from "./ExamApplyDescription";
import {
  PhotoUploader,
  SelectArea,
  SelectBirthday,
  SelectCountry,
  SelectIdCardType,
  SelectLevel,
  SelectNation,
  SelectPinyin,
  SelectSex
} from "./apply-util";

const {Step} = Steps

/**
 * 海报
 * @constructor
 */
function Poster() {
  const plan = useSelector((state: any) => state.plan);
  const dispatch = useDispatch();
  return (
    <div className='Poster' style={{background: `url(${poster}) no-repeat center fixed`, backgroundSize: 'cover'}}>
      <Helmet>
        <title>CCFA报名-海报</title>
      </Helmet>
      <div className='btn-box'>
        <div className='title'>{plan.info.specialityName}测评</div>
        <Button shape={"rounded"} className="reg-btn" size='large'
                onClick={() => dispatch.examApply.setData({steps: 0})}>立即报名</Button>
      </div>
    </div>
  )
}

/**
 * 第二步：测评信息
 */
function ExamInfo() {
  const plan = useSelector((state: any) => state.plan);
  const examApply = useSelector((state: any) => state.examApply);
  const [visibleLevel, setVisibleLevel] = useState(false);

  return (
    <div className="ExamInfo">
      <Helmet>
        <title>CCFA报名-测评信息</title>
      </Helmet>
      <List header={""}>
        <ListItem extra={plan.info.auditCompanyName}>
          测评中心
        </ListItem>
        <ListItem extra={plan.info.auditDate}>
          测评日期
        </ListItem>
        <ListItem extra={plan.info.specialityName}>
          测评专业
        </ListItem>
        <ListItem extra={examApply.level} onClick={() => setVisibleLevel(true)}>
          测评级别
        </ListItem>
      </List>
      <SelectLevel visible={visibleLevel} setVisible={setVisibleLevel}/>
    </div>
  )
}

/**
 * 第三步：考生信息
 */
function StudentInfo() {
  const plan = useSelector((state: any) => state.plan);
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();

  const [visiblePinyin, setVisiblePinyin] = useState(false);
  const [visibleIdCardType, setVisibleIdCardType] = useState(false);
  const [visibleBirthday, setVisibleBirthday] = useState(false);
  const [visibleSex, setVisibleSex] = useState(false);
  const [visibleNation, setVisibleNation] = useState(false);
  const [visibleCountry, setVisibleCountry] = useState(false);
  const [visibleArea, setVisibleArea] = useState(false);

  useEffect(() => {
    dispatch.weixin.getJsConfig({url: window.location.href}).then((res: any) => {
      wx.config({
        debug: false,
        appId: global.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: ['chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData', 'downloadImage', 'getLocalImgData'],
        openTagList: ['wx-open-launch-weapp'],
      })
    });
  }, [dispatch.weixin]);

  useEffect(() => {
    let idCardNumber = examApply.idCardNumber;
    if (examApply.idCardType === '身份证' && idCardNumber.length === 18) {
      let birthday = idCard.getBirthday(idCardNumber);
      let sex = idCard.getSex(idCardNumber);
      dispatch.examApply.setData({birthday, sex})
    }
  }, [dispatch.examApply, examApply.idCardNumber, examApply.idCardType]);

  //不要重复生成上传图片的key
  useEffect(() => {
    if (examApply.photo) return;
    dispatch.file.genKey({folder: 'photo'}).then((res: any) => {
      dispatch.examApply.setData({photo: res.data});
    });
  }, [examApply.photo, dispatch.examApply, dispatch.file]);

  return (
    <div className='StudentInfo'>
      <Helmet>
        <title>CCFA报名-人员信息</title>
      </Helmet>
      <List header={""}>
        <ListItem
          extra={<Input className={"input"} defaultValue={examApply.idCardName} clearable placeholder='请输入考生姓名'
                        onChange={(v) => dispatch.examApply.setData({idCardName: v})}/>}>
          考生姓名
        </ListItem>
        {
          examApply.inputPinyin ? (
            <ListItem extra={<Input className={"input"} defaultValue={examApply.namePinyin} clearable
                                    onChange={(v) => dispatch.examApply.setData({namePinyin: v})}/>}>
              姓名拼音
            </ListItem>
          ) : (
            <ListItem extra={examApply.namePinyin} onClick={() => setVisiblePinyin(true)}>
              姓名拼音
            </ListItem>
          )
        }
        <ListItem extra={examApply.idCardType} onClick={() => setVisibleIdCardType(true)}>
          证件类型
        </ListItem>
        <ListItem extra={<Input className={"input"} defaultValue={examApply.idCardNumber} placeholder='请输入证件号码'
                                onChange={(v) => dispatch.examApply.setData({idCardNumber: v})}/>}>
          证件号码
        </ListItem>
      </List>

      <List className={"list"}>
        <ListItem extra={examApply.birthday} onClick={() => setVisibleBirthday(true)}>
          出生日期
        </ListItem>
        <ListItem extra={examApply.sex} onClick={() => setVisibleSex(true)}>
          性别
        </ListItem>
      </List>

      <List className={"list"}>
        <ListItem extra={examApply.country} onClick={() => setVisibleCountry(true)}>
          国籍
        </ListItem>
        <ListItem extra={examApply.nation} onClick={() => setVisibleNation(true)}>
          民族
        </ListItem>
      </List>

      {
        //主持专业需要填写
        plan.info.itemType === 'HOSTING' && (
          <>
            <List className={"list"}>
              <ListItem extra={examApply.area} onClick={() => setVisibleArea(true)}>
                所在地区
              </ListItem>
              <ListItem extra={<Input className={"input"} defaultValue={examApply.address} clearable placeholder='可不填'
                                      onChange={(v) => dispatch.examApply.setData({address: v})}/>}>
                联系地址
              </ListItem>
            </List>
            <List>
              <ListItem
                extra={<Input className={"input"} defaultValue={examApply.orgName} placeholder='请输入机构名称' clearable
                              onChange={(v) => dispatch.examApply.setData({orgName: v})}/>}>
                机构名称
              </ListItem>
              <ListItem
                extra={<Input className={"input"} defaultValue={examApply.teacher} placeholder='请输入指导老师' clearable
                              onChange={(v) => dispatch.examApply.setData({teacher: v})}/>}>
                指导老师
              </ListItem>
              <ListItem extra={<Input className={"input"} type={"number"} defaultValue={examApply.teacherMobile}
                                      placeholder='请输入老师电话' clearable
                                      onChange={(v) => dispatch.examApply.setData({teacherMobile: v})}/>}>
                老师电话
              </ListItem>
            </List>

            <List className={"list"}>
              <ListItem extra={<Input className={"input"} defaultValue={examApply.parents} placeholder='请输入家长姓名'
                                      onChange={(v) => dispatch.examApply.setData({parents: v})}/>}>
                家长姓名
              </ListItem>
              <ListItem extra={<Input className={"input"} type={"number"} defaultValue={examApply.mobile}
                                      placeholder='请输入家长电话' clearable
                                      onChange={(v) => dispatch.examApply.setData({mobile: v})}/>}>
                家长电话
              </ListItem>
            </List>
          </>
        )
      }
      {
        //广场舞需要填写联系方式
        plan.info.itemType === 'SQUARE_DANCE' && (
          <List className={"list"}>
            <ListItem extra={<Input className={"input"} type={"number"} defaultValue={examApply.mobile}
                                    placeholder='请输入联系电话' clearable
                                    onChange={(v) => dispatch.examApply.setData({mobile: v})}/>}>
              联系电话
            </ListItem>
          </List>
        )
      }

      <List className={"list"}>
        <ListItem extra={<wx-open-launch-weapp
          id="launch-btn"
          username="gh_d69aab8d620c"
          path="pages/index/index"
        >
          <script type="text/wxtag-template">
            打开制作小程序
          </script>
        </wx-open-launch-weapp>}>
          制作证件照
        </ListItem>
        <ListItem extra={<PhotoUploader fileList={examApply.photoFileList}/>}>
          上传照片（二寸）
        </ListItem>
      </List>

      <SelectPinyin visible={visiblePinyin} setVisible={setVisiblePinyin}/>
      <SelectIdCardType visible={visibleIdCardType} setVisible={setVisibleIdCardType}/>
      <SelectBirthday visible={visibleBirthday} setVisible={setVisibleBirthday}/>
      <SelectSex visible={visibleSex} setVisible={setVisibleSex}/>
      <SelectNation visible={visibleNation} setVisible={setVisibleNation}/>
      <SelectCountry visible={visibleCountry} setVisible={setVisibleCountry}/>
      <SelectArea visible={visibleArea} setVisible={setVisibleArea}/>
    </div>
  )

}

/**
 * 大于1步才显示头部步骤条
 * @constructor
 */
function StepsHeader() {
  const examApply = useSelector((state: any) => state.examApply);

  return examApply.steps > 0 ? (
    <div className='StepsHeader'>
      <Steps current={examApply.steps}>
        <Step title='报名须知'/>
        <Step title='测评信息'/>
        <Step title='个人信息'/>
      </Steps>
    </div>
  ) : null;
}

/**
 * 大于1步才显示底部按钮
 * @constructor
 */
const StepsFooter = () => {
  const plan = useSelector((state: any) => state.plan);
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkExam = [
    {name: 'level', value: '请选择测评级别'},
  ];
  const checkStudent = [
    {name: 'idCardName', value: '请输入姓名'},
    {name: 'namePinyin', value: '请选择姓名拼音'},
    {name: 'idCardType', value: '请选择证件类型'},
    {name: 'idCardNumber', value: '请输入证件号码'},
    {name: 'birthday', value: '请选择出生日期'},
    {name: 'sex', value: '请选择性别'},
    {name: 'nation', value: '请选择民族'},
    {name: 'country', value: '请选择国籍'},
    // {name: 'area', value: '请选择所在地区'},
    // {name: 'address', value: '请输入联系地址'},
    // {name: 'photo', value: '请上传照片'},
  ];
  const checkMobile = [
    {name: 'mobile', value: '请输入联系电话'},
  ];
  const checkOrg = [
    // {name: 'orgName', value: '请输入机构名称'},
    {name: 'teacher', value: '请输入指导老师'},
    {name: 'teacherMobile', value: '请输入老师电话'},
    {name: 'parents', value: '请输入家长姓名'},
    {name: 'mobile', value: '请输入家长电话'},
  ];

  //下一步按钮
  const onStepBtn = (step: number) => {
    let steps = examApply.steps + step;
    // console.log(steps)
    //验证测评信息
    if (steps === 2) {
      for (let i = 0; i < checkExam.length; i++) {
        if (examApply[checkExam[i].name] === '') {
          Toast.show({
            icon: 'fail',
            content: checkExam[i].value,
          });
          return;
        }
      }
    }

    dispatch.examApply.setData({steps});
  }

  //提交报名
  const onFinish = () => {
    // console.log('onFinish');
    //验证个人信息
    for (let i = 0; i < checkStudent.length; i++) {
      if (examApply[checkStudent[i].name] === '') {
        Toast.show({
          icon: 'fail',
          content: checkStudent[i].value,
        });
        return;
      }
    }

    //fix身份证号码
    if (examApply.idCardType === '身份证' && examApply.idCardNumber.length !== 18) {
      Toast.show({
        icon: 'fail',
        content: '请输入18位身份证号码',
      });
      return;
    }

    //fix上传照片
    if (examApply.photoFileList.length === 0) {
      Toast.show({
        icon: 'fail',
        content: '请上传照片',
      });
      return;
    }
    //验证机构信息
    if (plan.info.itemType === 'HOSTING') {
      for (let i = 0; i < checkOrg.length; i++) {
        if (examApply[checkOrg[i].name] === '') {
          Toast.show({
            icon: 'fail',
            content: checkOrg[i].value,
          });
          return;
        }
      }
    }
    //验证广场舞联系方式
    if (plan.info.itemType === 'SQUARE_DANCE') {
      for (let i = 0; i < checkMobile.length; i++) {
        if (examApply[checkMobile[i].name] === '') {
          Toast.show({
            icon: 'fail',
            content: checkMobile[i].value,
          });
          return;
        }
      }
    }

    //提交
    dispatch.examApply.apply().then((res: any) => {
      if (res.code === 0) {
        Toast.show({
          icon: 'success',
          content: '报名成功',
        })
        navigate("/infoExamApply/" + res.data, {});//跳转到报名详情页
      } else {
        Toast.show({
          icon: 'fail',
          content: res.msg,
        })
      }
    });
  }

  return examApply.steps >= 0 ? (
    <div className='StepsFooter'>
      {examApply.steps === 0 ? (
        <div className='BtnDiv'>
          <Button block shape='default' color='primary' size='large'
                  onClick={() => onStepBtn(1)}>我已阅读，继续报名</Button>
        </div>
      ) : (
        <>
          <div className='BtnDiv'>
            <Button block shape='default' size='large' onClick={() => onStepBtn(-1)}>上一步</Button>
          </div>
          <div className='BtnDiv'>
            {
              examApply.steps < 2 ? (
                <Button block shape='default' size='large' color='primary'
                        onClick={() => onStepBtn(1)}>下一步</Button>) : (
                <Button block shape='default' size='large' color='primary' onClick={onFinish}>提交报名</Button>)
            }
          </div>
        </>)}
    </div>
  ) : null;
}

const NewExamApply: React.FC = () => {
  const plan = useSelector((state: any) => state.plan);
  const session = useSelector((state: any) => state.session);
  const examApply = useSelector((state: any) => state.examApply);
  const dispatch = useDispatch();

  useEffect(() => {
    if (global.getSessionKey() == null) return;

    const planId = new URLSearchParams(window.location.search).get('planId');
    if (planId != null && planId !== '') {
      dispatch.examApply.setData({planId});
      dispatch.plan.info({planId});
    } else {
      console.error("没有传入planId")
    }
  }, [session.isLogin, dispatch.examApply, dispatch.plan]);

  useEffect(() => {
    if (global.getSessionKey() == null) return;

    dispatch.country.list();
    dispatch.nation.list();
    dispatch.city.list();
  }, [session.isLogin, dispatch.city, dispatch.country, dispatch.nation]);

  return (
    <div className='NewExamApply'>
      <StepsHeader/>

      {examApply.steps === -1 && <Poster/>}
      {examApply.steps === 0 && <ExamApplyDescription/>}
      {examApply.steps === 1 && <ExamInfo/>}
      {examApply.steps === 2 && <StudentInfo/>}

      <StepsFooter/>
    </div>
  )
}

export default NewExamApply;