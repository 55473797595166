import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const pinyin = createModel<RootModel>()({
  state: {
    formatter: 3,//首字母大写
  }, // initial state
  reducers: {
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    /**
     * 读取拼音列表
     * @param payload
     * @param rootState
     */
    async list(payload, rootState) {
      let formatter:number = rootState.pinyin.formatter;
      let res = await request.get("/pub/v1/pinyin/list?chineseStr=" + payload + "&formatter=" + formatter);
      if (res.code > 0) {
        console.log(res.msg);
      }
      return res;
    }
  }),
})