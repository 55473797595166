import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";
import global from "../global";

export const valuationApply = createModel<RootModel>()({
  state: {
    planId: 0,//计划id
    steps: -1,//步骤
    level: '',//级别
    idCardName: '',
    idCardNumber: '',
    orgName: '',//所在单位
    mobile: '',//电话
    auditDate: '',//审核时间
    expireDate: '',//截止日期
    companyName: '',//公司名称
    photo: '',//照片

    photoFileList: [],//文件列表
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 读取用户信息
     * @param payload
     * @param rootState
     */
    async getInfo(payload, rootState) {
      let res = await request.get("/wemp/v1/valuation/apply/info/" + payload.id)
      if (res > 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 我的历史申请
     * @param payload
     * @param rootState
     */
    async getMy(payload, rootState) {
      let res = await request.get("/wemp/v1/valuation/apply/my/WECHAT")
      if (res > 0) {
        console.log(res.msg);
      }
      return res;
    },

    /**
     * 上传照片
     *
     * @param payload
     * @param rootState
     */
    async upload(payload, rootState) {
      let fileKey: string = rootState.valuationApply.photo;
      let res = await request.upload("/pub/v1/file/upload/cover", payload, {fileType: 'PRIVATE', fileKey});
      if (res.code > 0) {
        console.log(res.msg)
      }
      let url = global.getFileOpenUrl(res.data);
      return {url, key: res.data.fileKey, thumbnailUrl: url, extra: res.data.contentType};
    },

    /**
     * 申请评审
     * @param payload
     * @param rootState
     */
    async apply(payload, rootState) {
      let data: any = {
        planId: rootState.valuationApply.planId,
        level: rootState.valuationApply.level,
        idCardName: rootState.valuationApply.idCardName,
        idCardNumber: rootState.valuationApply.idCardNumber,
        photo: rootState.valuationApply.photo,
        orgName: rootState.valuationApply.orgName,
        mobile: rootState.valuationApply.mobile,
      }
      let res = await request.post("/wemp/v1/valuation/apply", data)
      if (res.code > 0) {
        console.log(res.msg);
      }
      return res;
    },

  }),
})