import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const user = createModel<RootModel>()({
  state: {
    mobile: '',
    smsId: 0,
    bind: false,
    info: {},
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    /**
     * 读取用户信息
     * @param payload
     * @param rootState
     */
    async getInfo(payload, rootState) {
      let res = await request.get("/wemp/v1/user")
      if (res.code === 0) {
        let bind = res.data.mobile !== null && res.data.mobile !== ''
        dispatch.user.setData({info: res.data, bind})
      }
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res
    },

    /**
     * 发送验证码
     * @param payload
     * @param rootState
     */
    async verifyCode(payload, rootState) {
      let res = await request.post("/wemp/v1/user/verifyCode", payload)
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res
    },

    /**
     * 绑定手机
     * @param payload
     * @param rootState
     */
    async bind(payload, rootState) {
      let smsId: number = rootState.user.smsId
      let res = await request.post("/wemp/v1/user/bind", {...payload, smsId})
      if (res.code !== 0) {
        console.log(res.msg);
      }
      return res
    }
  }),
})