import React from 'react';
import './CertQuery.less';
import query from "../../images/query.png";
import {PageFooter} from "../util";
import {useSelector} from "react-redux";
import {Button, Form, Input} from "antd-mobile";

const Success = () => {
  return (
    <></>
  )
}

const SearchForm = () => {
  const onFinish = (values: any) => {
    console.log(values);
  }
  return (
    <>
      <Form layout='vertical' footer={
        <Button block type='submit' color='primary' size='large'>
          查询
        </Button>
      }
            onFinish={onFinish}>
        <Form.Item label='姓名' name='idCardName' rules={[{required: true, message: '不能为空'}]}>
          <Input type={'text'} placeholder='请输入姓名' clearable autoFocus={true}/>
        </Form.Item>

        <Form.Item label='证件号码' name='idCardNumber'>
          <Input type="number" placeholder='请输入证件号码' clearable/>
        </Form.Item>

        <Form.Item label='证书编号' name='certNo'>
          <Input type="number" placeholder='请输入证书编号' clearable/>
        </Form.Item>

        <Form.Item label='准考证号' name='examNo'>
          <Input type="number" placeholder='请输入准考证号' clearable/>
        </Form.Item>

      </Form>
    </>
  )
}

const CertQuery: React.FC = () => {
  const cert = useSelector((state: any) => state.cert)

  return (
    <div className='CertQuery'>
      <div>
        <img src={query} alt="banner" style={{width: '100%'}}/>
      </div>
      <div className='bind'>
        <h3>查询证书</h3>
        {
          cert.info ? <Success/> : <SearchForm/>
        }
        <PageFooter/>
      </div>
    </div>
  )
}

export default CertQuery;