import {Models} from "@rematch/core"
import {session} from "./session";
import {examApply} from "./examApply";
import {user} from "./user";
import {pinyin} from "./pinyin";
import {file} from "./file";
import {nation} from "./nation";
import {country} from "./country";
import {applyScore} from './applyScore';
import {plan} from "./plan";
import {city} from "./city";
import {weixin} from "./weixin";
import {cert} from "./cert";
import {qrCodeLogin} from "./qrCodeLogin";
import {test} from "./test"
import {valuationApply} from "./valuationApply";

export interface RootModel extends Models<RootModel> {
  applyScore: typeof applyScore
  examApply: typeof examApply
  cert: typeof cert
  country: typeof country
  nation: typeof nation
  city: typeof city
  file: typeof file
  pinyin: typeof pinyin
  plan: typeof plan
  qrCodeLogin: typeof qrCodeLogin
  session: typeof session
  test: typeof test
  user: typeof user
  valuationApply: typeof valuationApply
  weixin: typeof weixin
}

export const models: RootModel = {
  applyScore,
  examApply,
  cert,
  country,
  nation,
  city,
  file,
  pinyin,
  plan,
  qrCodeLogin,
  session,
  test,
  user,
  valuationApply,
  weixin,
}
