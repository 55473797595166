import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const plan = createModel<RootModel>()({
  state: {
    info: {}
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async info(payload, rootState) {
      let res = await request.get("/wemp/v1/plan/" + payload.planId);
      if (res.code === 0) {
        dispatch.plan.setData({info: res.data});
      }
      return res;
    },
  }),
})