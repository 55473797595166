import {createModel} from "@rematch/core";
import {RootModel} from "./index";

export const cert = createModel<RootModel>()({
  state: {
    info: null,

  }, // initial state
  reducers: {
    // handle state changes with pure functions

  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions

  }),
})