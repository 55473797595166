import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ImageUploader, ImageUploadItem, Picker, Toast} from 'antd-mobile';
import global from "../../global";

/**
 * 老师选择级别
 *
 * @param props
 * @constructor
 */
export const SelectLevel = (props: { visible: boolean, setVisible: any }) => {
  const plan = useSelector((state: any) => state.plan);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<any[]>([]);

  //设置级别
  useEffect(() => {
    const basicColumns: any[] = [];
    basicColumns.push(global.getTeacherLevels(plan.info.itemType));
    // console.log('columns:', basicColumns)
    setColumns(basicColumns);
  }, [plan.info.itemType]);

  return (
    <Picker
      title='选择级别'
      columns={columns}
      visible={props.visible}
      // value={valuationApply.level}
      onClose={() => {
        props.setVisible(false)
      }}
      onConfirm={(v) => {
        let levelName = global.getTeacherLevelName(plan.info.itemType, Number(v[0]));
        dispatch.valuationApply.setData({level: v[0], levelName})}}
    />
  )

}

/**
 * 上传照片
 *
 * @constructor
 */
export const PhotoUploader = (props: { fileList: ImageUploadItem[] }) => {
  const dispatch = useDispatch();

  function beforeUpload(file: File) {
    if (file.size > 1024 * 512) {
      Toast.show('请选择小于 500K 的图片，可以用制作证件照小程序处理保存')
      return null
    }
    return file
  }

  return (
    <ImageUploader style={{margin: 10}}
                   value={props.fileList}
                   onChange={(v) => dispatch.valuationApply.setData({photoFileList: v})}
                   upload={(files: File) => dispatch.valuationApply.upload(files)}
                   showUpload={true}
                   multiple={false}
                   maxCount={1}
                   beforeUpload={beforeUpload}
      // onCountExceed={exceed => {
      //   Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
      // }}
    />
  )
}

