import React, {useEffect} from 'react';
import './MyApply.less';
import {Tabs} from "antd-mobile";
import MyValuationApply from "../myValuationApply/MyValuationApply";
import MyExamApply from "../myExamApply/MyExamApply";
import {useLocation} from "react-router";

const MyApply = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = React.useState('exam');
  useEffect(() => {
    if (location.state && location.state.tab) {
      setActiveKey(location.state.tab);
    }
  }, [location.state]);

  return (
    <div className={"MyApply"}>
      <Tabs defaultActiveKey={activeKey}>
        <Tabs.Tab title={"学生"} key={"exam"}>
          <MyExamApply/>
        </Tabs.Tab>
        <Tabs.Tab title={"老师"} key={"valuation"}>
          <MyValuationApply/>
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export default MyApply;