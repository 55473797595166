import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";
import global from "../global";

export const applyScore = createModel<RootModel>()({
  state: {
    info: null
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    setData(state, payload) {
      return {...state, ...payload}
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 测评申请
     *
     * @param payload
     * @param rootState
     */
    async getExamApply(payload, rootState) {
      let {applyId} = payload;
      let res = await request.get('/wemp/v1/apply/score/' + applyId);
      if (res.code !== 0) {
        console.log(res.msg)
      } else {
        dispatch.applyScore.setData({info: res.data})
      }
      return res;
    },

    /**
     * 提交测评成绩
     *
     * @param payload
     * @param rootState
     */
    async add(payload, rootState) {
      let res = await request.post('/wemp/v1/apply/score', payload);
      if (res.code !== 0) {
        console.log(res.msg)
      }
      return res;
    },

    /**
     * 上传文件
     *
     * @param payload
     * @param rootState
     */
    async upload(payload, rootState) {
      let res = await request.upload("/pub/v1/file/upload/add", payload, {fileType: 'PRIVATE', folder: 'exam'});
      if (res.code !== 0) {
        console.log(res.msg)
      }
      let url = global.getFileOpenUrl(res.data);
      return {url, key: res.data.fileKey, thumbnailUrl: url, extra: res.data.contentType};
    }
  }),
})