import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";
import global from "../global";

export const file = createModel<RootModel>()({
  state: {
    fileType: 'PRIVATE', //文件类型
    url: global.getApiServer() + "/pub/v1/file/upload",//上传地址
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    /**
     * 生成上传文件key，传入文件夹名称
     * @param payload
     * @param rootState
     */
    async genKey(payload, rootState) {
      let res = await request.get("/pub/v1/file/genKey/" + payload.folder);
      if (res.code > 0) {
        console.log(res.msg)
      }
      return res;
    },

  }),
})